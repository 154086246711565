%resize-at-breakpoint-md {
    @include media-query ($breakpoint-md) {
        width: 50%;
        min-width: $breakpoint-md;
        margin-left: auto;
        margin-right: auto;
    }
}

html,body {
    background-color: $background-color;
    height: 100%;
    margin: 0
}

body {
    display: flex;
    flex-direction: column;
}

header {
    padding: 2em 1em 0.5em 1em;
    border-bottom: 2px solid #ffffff;
    @extend %resize-at-breakpoint-md;
    a.site-title {
        text-decoration: none;
    }
    .site-logo {
        transform: translateY(1em)
    }
}

nav {
    font-size: 1.25em;
}

main {
    padding: 1em;
    flex: 1;
    @extend %resize-at-breakpoint-md;
}

article {
    flex: 1;
    width: 100%;
}

@mixin flex-col {
    flex-direction: column;
    align-items: baseline;
    > .flex-item {
        margin: 0.5em 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin flex-row {
    flex-direction: row;
    align-items: baseline;
    > .flex-item {
        margin: 0 1em;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    > .flex-left {
        margin-right: auto;
    }
    > .flex-right {
        margin-left: auto;
    }
}

.flex {
    display: flex;
    &.flex-col {
        @include flex-col;        
    }
    &.flex-col-md-row {
        @include flex-col;
        @include media-query($breakpoint-md) {
            @include flex-row;
        }
    }
    &.flex-row {
        @include flex-row;     
    }
    > .flex-item {
        display: block;
    }
   
}

.m-b-0-sm-1 {
    margin-bottom: 1em;
    @include media-query($breakpoint-md) {
        margin-bottom: 0;
    }
}

@include margin-classifiers();
@include padding-classifiers();

.img-responsive {
    img {
        display: block;
        margin: 0 auto;
    }
    .caption {
        font-style: italic;
        text-align: center;
    }
    display: block;
    width: 100%;
    padding: 1em 0;
}
