body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0;
    padding: 1em 0;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3, h4, h5, h6 {
    font-size: 1.25em;
}

a {
    color: $text-color;
    text-decoration: $link-decoration;
}

.bhead {
    font-weight: bold;
}

.byline {
    font-weight: normal;
    font-style: italic;
}

.highlight {
    font: $code-font-weight #{$code-font-size}/#{$code-line-height} $base-font-family;
}