$base-font-family: 'Ubuntu-Mono', sans-serif !default;
$base-font-size: 17px !default;
$base-font-weight: 400 !default;
$base-line-height: 1.25 !default;

$code-font-size: 14px !default;
$code-font-weight: 400 !default;
$code-line-height: 1.25 !default;

$text-color: #fff;
$link-color: #fff;
$link-decoration: underline;
$link-cursor: pointer;
$background-color: #1d1d1d; 

$breakpoint-md: 600px;
$breakpoint-sm: 400px;

@import 
  "monokai",
  "partials/mixins",
  "partials/fonts",
  "partials/typography",
  "partials/layout";