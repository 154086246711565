@mixin media-query($breakpoint) {
    @media screen and (min-width: $breakpoint) {
       @content; 
    }
}

@mixin margin-classifiers {
    @each $key, $side in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
        @each $size in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
            .m-#{$key}-#{$size} {
                margin-#{$side}: #{$size}em;
            }
        } 
    }
}

@mixin padding-classifiers {
    @each $key, $side in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
        @each $size in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
            .p-#{$key}-#{$size} {
                padding-#{$side}: #{$size}em;
            }
        } 
    }
}
